<template>
    <div>
        <h2>Call Tracking Setup (Optional)</h2>
        <p class="mb-4">
            Call Tracking will report the number of calls received to each trackable
            number and make a recording of the calls. you'll be able to view call log
            summaries and see useful caller data like: call duration, caller name, and
            campaign type.
        </p>
        <manage-dealer-call-tracking
            :dealer="currentDealer"
            class="mb-5"
            @update="onUpdateCallTracking" />
        <div class="layout align-center">
            <back-button
                @click="$store.dispatch('goToPreviousStep')" />
            <styled-button
                class="ml-auto"
                @click="$store.dispatch('goToNextStep')">
                CONTINUE
            </styled-button>
        </div>
    </div>
</template>

<script>
import ManageDealerCallTracking from '../../../globals/ManageDealerCallTracking';
import StyledButton from '../../../globals/StyledButton';
import BackButton from '../../../globals/BackButton';
import { mapState } from 'vuex';

export default {
    components: {
        BackButton,
        StyledButton,
        ManageDealerCallTracking
    },
    data() {
      return {};
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer
        })
    },
    methods: {
        onUpdateCallTracking() {
            this.$store.dispatch('updateCurrentDealer');
        }
    }
};
</script>
