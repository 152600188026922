<template>
    <div class="layout wrap">
        <div class="flex xs12 md8">
            <div class="layout wrap align-center call-tracking-number-wrap">
                <div class="flex xs12 offset-md4 md4">
                    <!-- Facebook Start -->
                    <manage-call-tracking-number
                        class=""
                        label="Facebook Ads"
                        :dealer="dealer"
                        type="facebook_ads"
                        :default-area-code="defaultAreaCode"
                        :tooltip-open.sync="openAdvertisingTooltip"
                        @update="$emit('update')" />
                    <!-- Facebook End -->
                </div>

                <div class="ml-auto xs12 forward-to">
                    Forwarded to
                </div>
            </div>
        </div>

        <div class="flex xs12 md4 pa-5 call-tracking-main-wrap">
            <!-- Main Start -->
            <manage-phone-number
                :dealer="dealer"
                label="Main Number"
                @update="$emit('update')" />
            <!-- Main End -->
        </div>
    </div>
</template>

<script>
import ManagePhoneNumber from './ManagePhoneNumber';
import ManageCallTrackingNumber from './ManageCallTrackingNumber';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export default {
    components: {
        ManageCallTrackingNumber,
        ManagePhoneNumber,
    },
    props: {
        dealer: {
            type: Object,
            required: true,
        },
        autoStart: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            openAdvertisingTooltip: false,
        };
    },
    computed: {
        defaultAreaCode() {
            if (!this.dealer.phone) {
                return;
            }
            const parsedNumber = parsePhoneNumberFromString(this.dealer.phone, this.dealer.country_code);

            return parsedNumber.nationalNumber.substring(0,3);
        }
    },
    mounted() {
        if (this.autoStart) {
            setTimeout(() => {
                this.openAdvertisingTooltip = true;
            }, 500);
        }
    }
};
</script>

<style lang="scss">

.call-tracking-header {
    position: relative;
    z-index: 5;
}

.call-tracking-number-wrap {
    background-color: $bg-light-container;
    position: relative;
    margin-right: 70px;
    padding-right: 40px;
    height: 140px;
    @media (max-width: $bp-md) {
        margin: 0 0 20px;
        padding: 20px 0 40px;
        height: auto;
        .data-field-wrapper {
            margin-bottom: 20px;
            text-align: center;
        }
        .data-field-data {
            padding-left: 0;
        }
        .forward-to {
            text-align: center;
            margin-left: 0 !important;
            width: 100%;
        }
    }
    &::after {
        display: block;
        content: '';
        position: absolute;
        right: -70px;
        top: 0;
        width: 0;
        height: 0;
        border-top: 70px solid transparent;
        border-bottom: 70px solid transparent;
        border-left: 70px solid $bg-light-container;
        @media (max-width: $bp-md) {
            top: auto;
            left: 50%;
            margin-left: -20px;
            bottom: -20px;
            border-bottom: none;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid $bg-light-container;

        }
    }
}
.call-tracking-main-wrap {
    @media (max-width: $bp-md) {
        padding: 20px 0 0 !important;
        .data-field-wrapper {
            margin-bottom: 20px;
            text-align: center;
        }
        .data-field-data {
            padding-left: 0;
        }
    }
}
</style>
