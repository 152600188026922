<template>
    <v-layout row>
        <v-flex xs12>
            <h3>{{ title }}</h3>
            <v-text-field
                ref="areaCodeField"
                :value="value"
                :rules="areaCodeRules"
                label="Desired Call Tracking Area Code"
                class="py-3 my-0 area-code-field"
                required
                @input="handleInput" />
            <v-layout row>
                <div
                    v-if="loadingCallTracking"
                    class="flex align-center">
                    <loader />
                </div>
                <div
                    v-else
                    class="call-tracking-data-wrapper">
                    <p
                        v-if="callTrackingNumbers.length"
                        class="my-0">
                        Select number:
                    </p>
                    <v-radio-group
                        v-model="selectNumber"
                        class="justify-space-around align-center">
                        <v-radio
                            v-for="(number, index) in callTrackingNumbers"
                            :key="index"
                            :label="number | formatPhoneNumber(country)"
                            :value="number"
                            class="radio-bg-white" />
                    </v-radio-group>
                    <p
                        v-if="error"
                        class="text-xs-center red--text">
                        No number could be found for the provided area code. Please search again.
                    </p>
                </div>
            </v-layout>
        </v-flex>
    </v-layout>
</template>

<script>
import { areaCodeRules } from '../../helpers/validationRules';
import ApiRepository from '../../api-repository';
import disableAutofill from '../../mixins/disable-autofill';
import Loader from '../globals/Loader';
import { get } from 'lodash';

export default {
    components: {
        Loader
    },
    mixins: [
        disableAutofill,
    ],
    props: {
        value: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        country: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            areaCodeRules: [],
            callTrackingNumbers: [],
            loadingCallTracking: false,
            selectNumber: '',
            error: false
        };
    },
    watch: {
        value() {
            this.$refs.areaCodeField.validate();
            if(!this.$refs.areaCodeField.valid) {
                this.callTrackingNumbers = [];
                return;
            }

            this.getCallTrackingNumbers();
        },
        selectNumber() {
            this.$emit('update', this.selectNumber);
        },
        country() {
            this.areaCodeRules = areaCodeRules(this.country);
        }
    },
    created() {
        this.$apiRepository = new ApiRepository();
        this.areaCodeRules = areaCodeRules(this.country);

        // Attempt to get call tracking numbers
        // this will only take action if we have a value (area code)
        this.getCallTrackingNumbers();
    },
    methods: {
        async getCallTrackingNumbers() {
            if (!this.value || !this.country) {
                return;
            }

            this.error = false;
            this.loadingCallTracking = true;
            this.callTrackingNumbers = [];

            try {
                const response = await this.$apiRepository.getCallTrackingNumbers(this.value, this.country);
                this.callTrackingNumbers = get(response, 'data.data', null) || [];
            } catch (error) {
                console.error('Error retrieving call tracking numbers',  error);
                this.error = true;
            } finally {
                this.loadingCallTracking = false;
            }
        },
        handleInput(value) {
            this.$emit('input', value);
        }
    },
};
</script>

<style lang="scss">
.area-code-field {
    .v-text-field__details {
        display: none;
    }
}
.call-tracking-data-wrapper {
    width:100%;
    max-height: 250px;
    overflow-y: auto;

    .accent--text {
        caret-color: #03a9f4 !important;
        color: #03a9f4 !important;
    }

    .radio-bg-white {
        background: #eef0f1;
        padding:5px 5px 5px 10px;
    }
    .v-radio {
        margin-right: 0;
    }
    .v-input--selection-controls {
        margin-top: 8px;
    }
}
</style>
