<template>
    <data-field>
        <template #label>
            {{ label }}
        </template>
        <styled-tooltip
            v-model="isEditing"
            :open-on-click="true"
            :max-width="320"
            position="bottom">
            <v-progress-circular
                v-if="loading"
                color="primary"
                width="2"
                size="15"
                indeterminate />
            <a @click.prevent="isEditing = !isEditing">
                <template v-if="currentPhoneNumber !== ''">
                    {{ currentPhoneNumber | formatPhoneNumber(dealer.country_code) }}
                </template>
                <template v-else>
                    Select number
                </template>
            </a>
            <template #content>
                <div class="phone-number-edit-tooltip">
                    <v-alert
                        :value="error"
                        type="error">
                        Error applying update.  Please try again or contact support if the problem persists.
                    </v-alert>
                    <v-alert
                        :value="!loading && showNumberAppliedMessage"
                        type="success">
                        Selected phone number successfully applied!
                    </v-alert>
                    <v-form
                        id="edit-phone-number-form"
                        v-model="valid"
                        @submit.prevent="handleSubmit">
                        <v-text-field
                            v-model.trim="newPhoneNumber"
                            label="Phone Number"
                            :rules="[...requiredRules, ...phoneRules]"
                            :hint="`Enter phone number as ${country.translations.phoneFormats}`"
                            class="py-3 my-0"
                            required />

                        <div class="text-xs-center">
                            <v-btn
                                type="submit"
                                form="edit-phone-number-form"
                                :loading="loading"
                                :disabled="!valid"
                                depressed
                                outline
                                color="primary"
                                class="text-none font-weight-bold">
                                Save
                            </v-btn>
                        </div>
                    </v-form>
                </div>
            </template>
        </styled-tooltip>
    </data-field>
</template>

<script>
import { phoneRules, requiredRules } from '../../helpers/validationRules';
import countryAreas from '../../helpers/countries';
import DataField from './DataField';
import HTTP from '../../http';
import StyledTooltip from './StyledTooltip';

export default {
    components: {
        DataField,
        StyledTooltip
    },

    props: {
        dealer: {
            type: Object,
            required: true,
        },
        label: {
            type: String,
            default: 'Phone Number'
        }
    },

    data() {
        return {
            currentPhoneNumber: '',
            country: null,
            error: false,
            isEditing: false,
            loading: false,
            newPhoneNumber: '',
            requiredRules,
            showNumberAppliedMessage: false,
            valid: false
        };
    },
    watch: {
        dealer() {
            this.currentPhoneNumber = this.getPhoneNumber();
            this.newPhoneNumber = this.getPhoneNumber();
            this.country = this.getCountry();
        }
    },
    created() {
        this.currentPhoneNumber = this.getPhoneNumber();
        this.newPhoneNumber = this.getPhoneNumber();
        this.country = this.getCountry();

        this.phoneRules = phoneRules(this.country.abbreviation);
    },
    methods: {
        async handleSubmit() {

            this.loading = true;
            this.error = false;

            try {
                const data = {
                    phone: this.newPhoneNumber
                };
                const url = `/dealers/${this.dealer.id}`;
                await (new HTTP).patch(url, data);

                this.$emit('update', this.newPhoneNumber);
                this.currentPhoneNumber = this.newPhoneNumber;
                this.loading = false;

                this.showNumberAppliedMessage = true;
                setTimeout(() => {
                    this.isEditing = false;
                    this.showNumberAppliedMessage = false;
                }, 2000);

            } catch(error) {
                // eslint-disable-next-line no-console
                console.error('Error adding post twilio lead sources:', error);
                this.error = true;
            } finally {
                this.loading = false;
            }


        },
        getPhoneNumber() {
            return this.dealer.phone || '';
        },
        getCountry() {
            if (!this.dealer.country_code) {
                return null;
            }

            return countryAreas.find(item => {
                return item.abbreviation === this.dealer.country_code;
            });
        }
    }
};
</script>

<style scoped>
.phone-number-edit-tooltip {
    min-width: 270px;
}
</style>
