<template>
    <data-field>
        <template #label>
            {{ label }}
        </template>
        <styled-tooltip
            :value="tooltipOpen"
            :open-on-click="true"
            :max-width="320"
            position="bottom"
            @input="$emit('update:tooltip-open', $event)">
            <v-progress-circular
                v-if="loading"
                color="primary"
                width="2"
                size="15"
                indeterminate />
            <a @click.prevent="$emit('update:tooltip-open', !tooltipOpen)">
                <template v-if="currentPhoneNumber !== ''">
                    {{ currentPhoneNumber | formatPhoneNumber(dealer.country_code) }}
                </template>
                <template v-else>
                    Select number
                </template>
            </a>
            <template #content>
                <div class="call-tracking-edit-tooltip">
                    <v-alert
                        :value="error"
                        type="error">
                        Error applying number.  Please try again or contact support if the problem persists.
                    </v-alert>
                    <!-- <v-alert
                        :value="loading"
                        type="info">
                        Applying selected phone number
                    </v-alert> -->
                    <v-alert
                        :value="!loading && showNumberAppliedMessage"
                        type="success">
                        Selected phone number successfully applied!
                    </v-alert>
                    <call-tracking-number
                        v-model="defaultAreaCode"
                        :country="dealer.country_code"
                        @update="onNumberSelected" />
                </div>
            </template>
        </styled-tooltip>
    </data-field>
</template>

<script>
import CallTrackingNumber from './CallTrackingNumber';
import DataField from './DataField';
import { get } from 'lodash';
import HTTP from '../../http';
import StyledTooltip from './StyledTooltip';


export default {
    components: {
        CallTrackingNumber,
        DataField,
        StyledTooltip
    },
    props: {
        label: {
            type: String,
            required: true
        },
        tooltipOpen: {
            type: Boolean,
            default: false
        },
        dealer: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            required: true
        },
        defaultAreaCode: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            currentPhoneNumber: '',
            error: false,
            //isEditing: false,
            showNumberAppliedMessage: false,
            loading: false,
            types: {
                facebook_ads: 1,
                marketplace: 2
            }
        };
    },
    computed: {
        twilioLeadSources() {
            return get(this, 'dealer.twilio_lead_sources.data', null) || [];
        }
    },
    watch: {
        dealer() {
            this.currentPhoneNumber = this.getPhoneNumber();
        }
    },
    created() {
        this.currentPhoneNumber = this.getPhoneNumber();
    },
    methods: {
        getPhoneNumber() {

            const typeId = this.types[this.type];

            if (!this.twilioLeadSources.length) {
                return '';
            }

            // Retrieve the phone number by type from the lead sources
            const source = this.twilioLeadSources.find(item => item.twilio_lead_source_type_id === typeId);
            if (source) {
                return source.twilio_number;
            }

            return '';
        },
        onNumberSelected(number) {
            // eslint-disable-next-line no-console
            console.log(`Setup for ${number} on ${this.type}`);

            this.setupCallTracking(number, this.type);
        },
        async setupCallTracking(number, assignment) {

            this.loading = true;
            this.error = false;

            try {
                const data = {
                    phone_numbers: [{
                        number,
                        assignment
                    }]
                };

                const url = `/dealers/${this.dealer.id}/setup-call-tracking`;
                await (new HTTP).post(url, data);

                this.showNumberAppliedMessage = true;

                this.$emit('update', number, assignment);
                this.currentPhoneNumber = number;

                setTimeout(() => {
                    //this.isEditing = false;
                    this.$emit('update:tooltip-open', false);
                    this.showNumberAppliedMessage = false;
                }, 1500);

            } catch(error) {
                // eslint-disable-next-line no-console
                console.error('Error adding post twilio lead sources:', error);
                this.error = true;
            } finally {
                this.loading = false;
            }
        },
    }
};
</script>
<style scoped>
.call-tracking-edit-tooltip {
    min-width: 270px;
}
</style>
